import React, { useState } from 'react';

import {ApiReferenceReact} from '@scalar/api-reference-react'
import '@scalar/api-reference-react/style.css'
import styled from 'styled-components';
import './App.css';
import Header from './Header';
import logo from './mobble-logo.png';

const AppContainer = styled.div`
    padding-top: 10px; // This should be equal to the height of the Header
`;
// http://localhost:8080/v1/docs/openapi.yaml
const App: React.FC = () => {
    const initialToken = '';
    const [token, setToken] = useState(initialToken);
    const handleLoginSuccess = (token: string) => {
        setToken(token);
    };

    const [apiSpecUrl, setApiSpecUrl] = useState('https://api.production.aws.mobble.io/v1/docs/openapi.yaml'); // Initial state for API spec URL

    const handleApiSpecChange = (newUrl: string) => {
        console.log('Selected API URL:', newUrl);
        setApiSpecUrl(newUrl);
    };

    return (
        <div className={'App'}>
            <Header logoSrc={logo} height="60px" onLoginSuccess={handleLoginSuccess} onAPISpecSelect={handleApiSpecChange}/>

            <AppContainer>
            <ApiReferenceReact
                configuration={{
                    darkMode: false,
                    spec: {
                        url: apiSpecUrl,
                    },
                    authentication: {
                        preferredSecurityScheme: 'BearerAuth',
                        http: {
                            basic:{
                                username:'',
                                password: ''
                            },
                            bearer: {
                                token: token
                            }
                        },
                    }
                }}
            />
            {/*<BaseMap />*/}
            </AppContainer>
        </div>
    );
};

export default App;

